import './Contact.css'
import './Homepage.css'
import './App.css'
import resume from './images/resume.pdf'

function Contact() {
    return (
        <div className = "homediv">
            <h1 className="hometitles">Contact Me</h1>
            <h3 className="hometitles">I am looking for entry-level front-end and back-end software engineering opportunities!</h3>
            <div id = "contactdiv">
                <div className = "contactcontainer">
                    <h2>Email:</h2>
                    <h5>gvhmann@gmail.com</h5>
                </div>
                <div className = "contactcontainer">
                    <h2>LinkedIn:</h2>
                    <h5><a href = "https://www.linkedin.com/in/george-herrmann-b16b71253" target="_blank" rel="noopener noreferrer">Go to my LinkedIn</a></h5>
                </div>
                <div className = "contactcontainer">
                    <h2>Github:</h2>
                    <h5><a href = "http://github.com/GeorgeHerrmann" target="_blank" rel="noopener noreferrer">Go to my Github</a></h5>
                </div>
                <div className = "contactcontainer">
                    <h2>Resume:</h2>
                    <h5><a href = {resume} target="_blank" rel="noopener noreferrer">View my resume</a></h5>
                </div>
            </div>
        </div>
    )
}

export default Contact;