import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link}
    from 'react-router-dom';

import './App.css';
import Soapbot from './Soapbot'
import Homepage from './Homepage'
import Contact from './Contact'
import Projects from './Projects';

function App() {
  let routes = (
    <Router>
    <Navbar />
    <Routes>
        <Route exact path='/' element={<Homepage />} />
        <Route path='/soapbot' element={<Soapbot />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/projects' element={<Projects />} />
    </Routes>
    <Footer />
    </Router>
  );
  return routes;
}

const Navbar = () => {
  return (
  <div id = "navbar">
    <h1 id = "navbarheader">
      <Link to = "/">George V. Herrmann</Link>
    </h1>
    <div id = "navelements">
      <li className = "navelement">
        <Link to="/">Home</Link>
      </li>
      <li className = "navelement">
        <Link to="/projects">Projects</Link>
      </li>
      <li className = "navelement">
        <Link to="/soapbot">SOAP Bot</Link>
      </li>
      <li className = 'navelement'>
        <Link to="/contact">Contact</Link>
      </li>
      <li className = "navelement">
        <a href = "http://github.com/GeorgeHerrmann" target="_blank" rel="noopener noreferrer">Portfolio</a>
      </li>
    </div>
  </div>
  );
}

const Footer = () => {
  return (
    <div id = "footer">
      <p>&copy; George V. Herrmann</p>
    </div>
  );
}

export default App;
