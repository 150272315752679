import {useState, useEffect} from 'react';
import { getAction } from './services/FromApi';
import './Soapbot.css'
import soapbothighlevel from './images/soapbot_highlevel.png';

function Soapbot() {
    let [updater, changeUpdater] = useState([]);
    let [action, setAction] = useState("Updating...");
    useEffect(() => {
        setTimeout(() => {
            getAction().then(data => {
                setAction(data.action);
                changeUpdater([]);
            });
        }, 50);
    }, [updater]);
    return (
        <div className = "soapdivcontainer">
            <div className  = "soapdiv">
                <h1 id = "soaptitle">SOAP Bot is currently:</h1>
                <p id = "actionText">{action}</p>
                <a href = "https://github.com/GeorgeHerrmann/soapbot" target="_blank" rel="noopener noreferrer">Source Code</a>
            </div>
            <div className = "soapdiv">
                <h1 id = "soaptitle">What is SOAP Bot?</h1>
                <p className = "infotext">SOAP Bot is a highly efficient and capable Discord bot written in Java using the Discord4J wrapper.
                At a high level, a ClientManager controls all SOAPClient's in a shard, which corresponds to each Guild/server SOAP Bot is in.
                This system of systems design allows SOAP Bot to have highly complex systems for each server it is in, while maintaining a high level of speed and low memory usage.
                The systems are only active once the ClientManager distributes Discord's events to the Client. All of SOAP Bot runs in a 1 vcore 1GB RAM server.</p>

                <img src = {soapbothighlevel} alt = "SOAP Bot High Level Diagram"></img>
                <p></p>

                <h2 id = "soaptitle">So what can it do?</h2>
                <p className = "infotext">SOAP Bot has a wide variety of features, including:</p>
                <ul className = "infotext">
                    <ul className = "infotexthead">
                        A simple economy system
                        <p></p>
                        Simulating games in text-channels (ex: plinko, blackjack)
                        <li className = "infotext">&#9642; Features a game system framework, as well as a playing card system</li>
                        <p></p>
                        Playing audio from Youtube, Soundcloud, etc. in a voice channel
                        <li className = "infotext">&#9642; Features a queue system</li>
                        <li className = "infotext">&#9642; Allows the queuing of playlists</li>
                        <li className = "infotext">&#9642; Allows for complete autonomy between servers</li>
                        <p></p>
                        A complex and functional event system, allowing users to schedule various types of events
                        <li className = "infotext">&#9642; Events are saved and scheduled, even if the bot happens to go offline.</li>
                        <li className = "infotext">&#9642; Features a high level of flexibility, allowing for various types of events (reserve and poll events) with times, player counts, etc.</li>
                        <li className = "infotext">&#9642; Allows users to manage events and features utility for events</li>
                        <p></p>
                        A fully-featured permissions system that integrates with Discord's role system.
                        <p></p>
                        A back and forth dynamic conversational system
                        <li className = "infotext">&#9642; Allows for users to input data via "wizards" or have basic conversations with SOAP Bot</li>
                        <li className = "infotext">&#9642; The Wizard and Listener system allows a high degree of flexibility in how SOAP Bot can interact with users</li>
                        <p></p>
                        A fully-featured trading card system
                        <li className = "infotext">&#9642; Allows users to collect cards and trade them with other users</li>
                        <li className = "infotext">&#9642; Cards contain values relative to the total coins in a Guild</li>
                        <li className = "infotext">&#9642; Card values can change over time, affecting their rarities</li>
                        <li className = "infotext">&#9642; Features a marketplace where users can sell their cards, or they can use the automated selling and buying system</li>
                        <li className = "infotext">&#9642; Runs on highly customized wizards, which can create easy to understand user interfaces</li>
                        and much more!
                    </ul>
                </ul>

                <h2 id = "soaptitle">How does SOAP Bot work?</h2>
                <section>
                    <h2>General Overview</h2>
                    <div class="infotext">
                        <p>
                        <strong>SOAP Bot</strong> is a robust Discord bot engineered using the <a href="https://discord4j.com/" target="_blank" rel="noopener noreferrer">Discord4J</a> library, 
                        a powerful Java wrapper that facilitates seamless interaction with the Discord API. The object-oriented nature of Discord4J allows for a modular and scalable codebase, making the development and maintenance of the bot both efficient and effective.
                        </p>
                        <p>
                        At the heart of Discord4J lies the <a href="https://projectreactor.io/" target="_blank" rel="noopener noreferrer">Reactor</a> framework, which the bot utilizes to handle asynchronous data streams through <em>Mono</em> and <em>Flux</em> constructs. This reactive programming approach grants the SOAP Bot the ability to manage data sequences with ease, whether they represent a single result (Mono) or multiple results (Flux). This reactive model is particularly adept at handling event-driven environments, making it an ideal choice for the event-rich ecosystem of Discord, as, until some Discord event is recieved, SOAP Bot does pretty much nothing while running.
                        <p>
                        In this page, I will not go into detail regarding every SOAP Bot system, rather the crucial ones and features which make SOAP Bot unique.
                        </p>
                        </p>
                        <p>
                        Central to the bot's operation is the <code>GatewayDiscordClient</code>, which acts as the primary conduit for connecting to the Discord API.
                        </p>
                    </div>
                </section>

                <section>
                    <h2>Soap Client Structure</h2>
                    <div class="infotext">
                        <p>
                        The architecture of <strong>SOAP Bot</strong> is ingeniously crafted into a hierarchical, system-of-systems framework. Central to this architecture is the <code>SoapClientManager</code>, the strategic command center that orchestrates the operation of each individual <code>SoapClient</code>. It's through the <code>SoapClientManager</code> that the <code>GatewayDiscordClient</code> is housed and leveraged, interfacing directly with the Discord gateway to meticulously define and handle event subscriptions and interactions.
                        </p>
                        <p>
                        A pivotal feature of the <code>SoapClientManager</code> is its sophisticated mapping system, where it maintains a comprehensive map of <code>SoapClients</code>. Each <code>SoapClient</code> is uniquely associated with a Discord Guild, identified using the Guild's distinct Snowflake ID. This allows for a tailored and organized management of each Guild-specific instance of the bot.
                        </p>
                        <p>
                        Within every <code>SoapClient</code>, lies an ecosystem of subsystems critical to the bot's operation within a Guild. These include the <code>AudioContext</code>—an assembly of components handling audio playback and queue management, the <code>CommandRegistry</code>—a system dedicated to processing and responding to user commands, and various <code>SoapManagers</code>—custodians of object management for persistent database storage.
                        </p>
                        <p>
                        This structured separation ensures that all systems are Guild-specific, promoting an environment where commands, events, and audio within one Guild remain insulated from another. This isolation not only enhances performance but also amplifies security and customizability. The <code>ClientContext</code> associated with each <code>SoapClient</code> provides a seamless interface for systems to access and utilize Guild-specific information, showcasing the bot's capability for rapid and efficient creation of customized Guild systems.
                        </p>
                    </div>
                </section>

                <section>
                    <h2>Command Structure</h2>
                    <div class="infotext">
                        <p>
                        In the realm of <strong>SOAP Bot</strong>, commands are the fundamental units of operation, each with a basic definition that outlines their properties and the actions to be executed. The <code>Command</code> interface is the cornerstone of this system, representing an operation triggered by specific aliases. Whether prefaced by '!' or '/', or invoked via a Discord slash-command, a <code>Command</code> springs into action, provided it matches a valid alias. The design philosophy here is one of targeted functionality; each <code>Command</code> is atomic, avoiding the complexity of subcommands—for instance, <code>!queue</code> simply presents the list of tracks.
                        </p>
                        <p>
                        To accommodate more nuanced operations, the <code>ParseableCommand</code> comes into play. It extends the basic <code>Command</code> with a <code>CommandParser</code> that defines parsing logic, enabling the formulation of subcommands. This dual structure enriches the bot's interaction capabilities, allowing for a more sophisticated command hierarchy.
                        </p>
                        <p>
                        Command execution is a multi-faceted process that begins with the validation of input from an event. The <code>DiscordEvent</code>, a custom wrapper for Discord's <code>MessageCreateEvents</code> or <code>ApplicationCommandInteractionEvents</code>, serves as the starting point. This wrapped event is then transmuted into a <code>CommandExecutionEvent</code>, which may or may not signal the execution of a command, depending on several criteria. Among these are the syntactic alignment of user input with a <code>ParseableCommand's</code> parser pattern and the user's permissions.
                        </p>
                        <p>
                        Upon satisfying all preconditions, the <code>CommandExecutionEvent</code> kicks off the command execution sequence. This pivotal event provides a gateway to the <code>ClientContext</code>, enabling commands to interact with their originating <code>SoapClient</code>. A <code>MultiLogger</code> is engaged to record the proceedings, and interaction handlers are established for guild and user interactions. The <code>CommandExecutionEvent</code> is thus the linchpin in the command processing mechanism, fundamental to the bot's scalability. It encapsulates the requisite information and, once all checks are cleared, orchestrates the execution by invoking the <code>Command</code>'s <code>execute</code> method, a testament to its central role in feature development and scalability. Any command-originating system is initialized at least in-part with a CommandExecutionEvent.
                        </p>
                    </div>
                </section>

                <section>
                    <h2>InteractionHandlers</h2>
                    <div class="infotext">
                        <p>
                        InteractionHandlers in SOAP Bot serve as a critical abstraction layer that refines the interaction with Discord, be it with a Guild or a User. This abstract class elevates the functionality above the standard Discord4J calls, streamlining the communication process. InteractionHandlers are classified into two primary categories: <code>UserInteractionHandler</code> and <code>GuildInteractionHandler</code>, both of which underpin the bot's flexible response system.
                        </p>
                        <p>
                        These handlers incorporate a set of predefined logic that enables SOAP Bot to adapt its responses to a variety of events, thanks to special configurations. They maintain various <code>Optional</code> objects that, when present, allow the InteractionHandler to automatically adjust its response behavior. For instance, an active <code>MessageChannel</code> ensures that SOAP Bot responds in the correct channel, as determined by the <code>CommandExecutionEvent</code>, without requiring manual direction from the command or subsystem.
                        </p>
                        <p>
                        Furthermore, InteractionHandlers define how a response should "look" with embeds, coloring, etc. With all these properties equipped, a command could simply call sendMessage(String text, String title) and the InteractionHandler would automatically respond to the correct channel with the correct formatting based on the configuration provided by higher-level systems which recieved different properties from Discord.
                        </p>
                        <p>
                        The <code>GuildInteractionHandler</code> plays a versatile role when it comes to Guild interactions. It possesses the ability to respond directly to an <code>ApplicationCommandInteraction</code>, taking precedence over other inputs or message channels. Additionally, it is equipped to handle <code>ComponentInteraction</code>, interpreting any message edit requests in the context of component-triggered interactions, a feature particularly beneficial for SOAP Bot's Wizard system (to be detailed later).
                        </p>
                        <p>
                        Beyond handling responses, <code>GuildInteractionHandlers</code> are imbued with utilities for extracting and utilizing Guild-specific information. These include identifying users or members by IDs or usernames, retrieving Guild properties, and accessing roles, which are essential for the bot’s permission system. This suite of capabilities positions InteractionHandlers as a cornerstone for efficient and intelligent bot operations within Discord.
                        </p>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Soapbot;