import {Link} from 'react-router-dom';

import './Projects.css'

function Projects() {
    return (
        <div className = "projectsdiv">
            <div className = "projectcontainer">
                <h1 className = "projecttitle">SOAP Bot</h1>
                <p>
                    <strong>SOAP Bot</strong> is a robust, multipurpose Discord bot built in Java utilizing the Discord4J library and the Reactor framework. It offers a comprehensive suite of interactive features, including game simulations (Plinko, Blackjack), audio playback capabilities via YouTube and SoundCloud, and dynamic event and polling systems with persistent scheduling.
                </p>
                <p>
                    Designed with a highly modular and scalable architecture, SOAP Bot efficiently manages multiple Discord guilds through a strategic system-of-systems approach. Each guild is managed by a dedicated <em>SoapClient</em>, ensuring isolated operations, enhanced performance, and robust security. SOAP Bot seamlessly integrates with Discord's API via the <em>GatewayDiscordClient</em>, maintaining responsiveness and efficiency even on resource-constrained environments (1 vCPU, 1GB RAM).
                </p>
                <p>
                    Key highlights of SOAP Bot include:
                </p>
                <ul className = "projectlist">
                    <li><strong>Interactive Game Simulations:</strong> Engaging user experiences with games such as Blackjack and Plinko.</li>
                    <li><strong>Advanced Audio Management:</strong> Independently managed audio playback, queues, and playlist controls.</li>
                    <li><strong>Comprehensive Event System:</strong> Allows users to create, schedule, and manage diverse events reliably.</li>
                    <li><strong>Flexible Polling System:</strong> User-friendly interface for creating, managing, and interacting with polls.</li>
                    <li><strong>Intelligent Permissions Management:</strong> Seamlessly integrates with Discord roles, providing granular access control.</li>
                    <li><strong>Conversational Input Wizard:</strong> Facilitates dynamic user interactions and streamlined data input through interactive wizards.</li>
                    <li><strong>OpenAI Integration:</strong> Utilizes OpenAI's technology for enhanced conversational AI capabilities, trained specifically for the SOAP Bot ecosystem.</li>
                </ul>
                <p>
                    The bot’s codebase is open-source, highly organized, and thoroughly documented, encouraging ongoing development.
                </p>
                <Link to = "/soapbot">Learn more about SOAP Bot</Link>
            </div>

            <div className = "projectcontainer">
                <h1 className = "projecttitle">Cycle Sisters</h1>
                <p className = "projectdescription">
                <p>
                    I worked as a freelance developer for <strong>Cycle Sisters,</strong> an e-commerce startup specializing in naturopathic products for women. Serving as the sole freelance developer, I architected, designed, and deployed the entire website, managing both frontend and backend components independently.
                </p>
                <p>
                    Leveraging the Velo by Wix platform, I developed a seamless and responsive frontend using <strong>React.js</strong>, while implementing robust backend services built with <strong>Node.js</strong> and a securely structured <strong>MongoDB</strong> database hosted on <strong>AWS EC2</strong>.
                </p>
                <p>
                    Highlights of my contribution include:
                </p>
                <ul className = "projectlist">
                    <li><strong>End-to-End Development:</strong> Sole responsibility for complete lifecycle development from design through deployment.</li>
                    <li><strong>Secure Authentication:</strong> Integrated OAuth2 protocol enabling secure Google SSO authentication, ensuring efficient and safe user management.</li>
                    <li><strong>Database Optimization:</strong> Designed optimized MongoDB schemas for effective and secure storage of user data.</li>
                    <li><strong>Impactful Results:</strong> Successfully launched the platform, rapidly attracting significant user traffic and enhancing the company's online visibility and sales.</li>
                </ul>
                <p>
                    This role demonstrated my ability to independently drive complex projects, implement secure and scalable solutions, and deliver measurable business impact.
                </p>
                <a href = "https://www.cyclesistersapothecary.com/" target="_blank" rel="noopener noreferrer">View Cycle Sisters Live Website</a>
                </p>
            </div>

            <div className = "projectcontainer">
                <h1 className = "projecttitle">CineCity</h1>
                <p className = "projectdescription">
                <p>
                    <strong>CineCity</strong> is a semester-long collaborative project developed as part of the Software Engineering curriculum at the University of Georgia. As team lead and primary backend engineer, I coordinated a group of five students to create a responsive, full-stack mock movie ticket booking platform.
                </p>
                <p>
                    CineCity's architecture featured a modern frontend built with <strong>React.js</strong>, a robust backend powered by <strong>Java Spring Boot</strong>, and a custom-designed <strong>SQL database</strong> hosted on <strong>Microsoft SQL Server</strong>. My role involved orchestrating development efforts, ensuring adherence to agile methodologies, and maintaining high standards through sprint reviews and feature presentations.
                </p>
                <p>
                    Notable contributions include:
                </p>
                <ul className = "projectlist">
                    <li><strong>Backend Development Lead:</strong> Designed and implemented a structured MVC architecture utilizing Java and the Spring Framework, ensuring clear separation of concerns and maintainability.</li>
                    <li><strong>Database Engineering:</strong> Assisted in designing and implementing a custom SQL database schema, optimized for performance and scalability.</li>
                    <li><strong>Frontend Integration:</strong> Integrated React frontend seamlessly with backend services, deployed on <strong>AWS EC2</strong> with database hosting via <strong>AWS RDS</strong>.</li>
                    <li><strong>Project Leadership:</strong> Managed coordination among team members, ensuring timely delivery and high-quality project outcomes.</li>
                </ul>
                <p>
                    CineCity was recognized as one of the top-performing projects in our class, demonstrating effective collaboration, technical expertise, and leadership capabilities.
                </p>
                <a href = "https://github.com/GeorgeHerrmann/csci4050backend" target="_blank" rel="noopener noreferrer">View CineCity's Backend Repository</a>
                </p>
            </div>
        </div>
    )
}

export default Projects;