import {Link} from 'react-router-dom';

import './Homepage.css'
import './App.css'
import backgroundimg from './images/backgroundcode.PNG';

function Homepage() {
    return (
        <div className = "homediv">
            <div id = "titlesdiv">
                <img src = {backgroundimg} alt = "background SOAP Bot code" height = "600px"></img>
                <div id = "titles">
                    <h1 className = "hometitles">George V. Herrmann</h1>
                    <h3 className = "hometitles">A Full-Stack software engineer</h3>
                    <Link to = "/projects" className = "viewmyprojects">View my projects</Link>
                </div>
            </div>
            <div id = "projects">
                <h2>My Projects:</h2>
                <div id = "homeinfogrid">
                    <div className = "infogriditem">
                        <h1>SOAP Bot</h1>
                        <p>
                            SOAP Bot is a multipurpose, object-oriented Discord bot written in Java using the Discord4J library.
                            SOAP Bot features fully fledged event and poll systems, game simulation (including Plinko and Blackjack),
                            an economy system, a complex trading card system, music playback, along with a suite of other commands.
                            SOAP Bot has a basic API via the <a href = "http://github.com/GeorgeHerrmann/soapapi" target="_blank" rel="noopener noreferrer">SOAP API</a>
                        </p>
                        <Link to = "/soapbot">Learn more about SOAP Bot</Link>
                    </div>
                    <div className = "infogriditem">
                        <h1>Cycle Sisters</h1>
                        <p>
                            I worked as a freelance web developer for the Cycle Sisters website, an e-commerce women's health supplement site.
                            As I was the sole developer, I was responsible not only for functionally implementing the site, but also deciding on the
                            tech stack that would be used. Cycle Sisters is built on Velo by Wix, a platform that allows for simple front-end design
                            while offering a robust and diverse back-end API to build off of. Cycle Sisters written with React and Node.js with a MongoDB
                            database.
                        </p>
                        <a href = "https://www.cyclesistersapothecary.com/" target="_blank" rel="noopener noreferrer">View Cycle Sisters Live Website</a>
                    </div>
                    <div className = "infogriditem">
                        <h1>CineCity</h1>
                        <p>
                            CineCity is a collaborative mock movie ticket booking site done by a team consisting of myself and four other individuals.
                            CineCity's front end was written using React, and Spring-Boot was used for the backend. A custom SQL database was used for persistent
                            storage, running on Microsoft SQL Server. CineCity was one of the top projects for our Software Engineering class.
                        </p>
                        <a href = "https://github.com/GeorgeHerrmann/csci4050backend" target="_blank" rel="noopener noreferrer">View CineCity's Backend Repository</a>
                    </div>
                </div>
                <p>
                    I am currently looking for software engineering opportunities! Please <Link to = "/contact">Contact Me</Link> to get in touch or view my resume.
                </p>
            </div>
        </div>
    )
}

export default Homepage;